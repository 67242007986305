const quantityWrapper = document.querySelectorAll(".quantity-wrapper");

if (quantityWrapper) {
  quantityWrapper.forEach((el) => {
    const quantityMinus = el.querySelector(".quantity__minus");
    const quantityPlus = el.querySelector(".quantity__plus");
    const quantityInput = el.querySelector(".quantity input");
    let quantityValue = 1;

    quantityPlus.addEventListener("click", () => {
      quantityInput.value = quantityValue + 1;
      quantityValue = parseInt(quantityInput.value);
    });

    quantityMinus.addEventListener("click", () => {
      quantityValue = parseInt(quantityInput.value);
      if (parseInt(quantityInput.value) > 1) {
        quantityInput.value = quantityValue - 1;
      }
    });
  });
}

const siteHeader = document.querySelector(".site-header");

if (siteHeader) {
  let scrollPos = window.scrollY;
  const scrollChange = 125;
  window.addEventListener("scroll", () => {
    scrollPos = window.scrollY;
    console.log(scrollPos);
    if (scrollPos >= scrollChange) {
      siteHeader.classList.add("small-header");
    } else siteHeader.classList.remove("small-header");
  });
}
