const menu = document.querySelector(".site-header--mobile-menu .mobile-menu");
const menuBtn = document.querySelector(".site-header--mobile-menu .button");
const open = document.querySelector(".site-header--mobile-menu .open");
const close = document.querySelector(".site-header--mobile-menu .close");

if (menuBtn) {
  menuBtn.addEventListener("click", () => {
    if (menu && menu.classList.contains("mobile-menu--active")) {
      open.style.display = "block";
      close.style.display = "none";
      menu.classList.remove("mobile-menu--active");
    } else {
      menu.classList.add("mobile-menu--active");
      open.style.display = "none";
      close.style.display = "block";
    }
  });
}
