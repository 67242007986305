import L from "leaflet";

const map = document.getElementById("map");

if (map) {
  const lMap = L.map("map", {
    center: [50.7516973, 19.2774146],
    zoom: 15,
  });
  L.marker([50.7516973, 19.2774146]).addTo(lMap);

  L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(lMap);
}
